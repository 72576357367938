import React from "react";
import './style.scss';

const Contactpage= () =>{
    return(
        <>
        <div className="contactsection">
            <div className="container">
                <div className="row">
                    <div className="col-6">
                        <h2>Lets Work <br/>Together</h2>
                    </div>
                    <div className="col-6">
                        <p>we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease.</p>
                    </div>
                </div>
                <div className="row" style={{marginTop:'40px'}}>
                    <div className="col-6">
                        <form action="">
                            <div className="d-flex">
                                <input type="text" placeholder="Name" />
                                <input type="email" placeholder="Email" />
                            </div>
                            <textarea name="message" placeholder="Message"/>
                            <input type="submit" value="Submit" />
                        </form>
                    </div>
                    <div className="col-6 contact-info">
                        <p>Email</p>
                        <h4>info@redmotionlab.com</h4>

                        <p>Location</p>
                        <h4>Delhi</h4>

                        <p>Contact</p>
                        <h4>+91 7292048577</h4>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Contactpage;