import React from "react";
import './style.scss';

const ContactSection=()=>{
    return(
        <section className="contact-section">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-12 getintouch">
                        <h2>Get in Touch</h2>
                        <h5>Phone No.</h5>
                        <p>7292048577</p>
                        <h5>EMail</h5>
                        <p>Info@Redmotionlab.com,<br/>Redmotionlab@gmail.com</p>
                        <h5>Social Media</h5>
                    </div>
                    <div className="col-sm-6 col-12">
                        <h4>Contact Us</h4>
                        <form action="">
                            <label>
                                <input type="text" name="name" placeholder="Your Name"/>
                            </label>
                            <label>
                                <input type="email" name="Email" placeholder="Your Email"/>
                            </label>
                            <label>
                                <input type="telephone" name="Phone No." placeholder="Your Phone No."/>
                            </label>
                            <label>
                                <textarea name="description" placeholder="Describe your Need "/>
                            </label>
                            <input type="submit" value="Submit" />
                        </form>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContactSection;