import React from "react";
import './style.scss';
import { images } from "../../../assets";

const HeroSection=()=>{
    return(
        <section className="Hero-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                    <h2><span>Empowering </span> Your Digital Transformation</h2>
                    <a href="#business">
                        <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="35" cy="35" r="34.5" fill="white" fill-opacity="0.41" stroke="white"/>
                            <path d="M37 14C37 12.8954 36.1046 12 35 12C33.8954 12 33 12.8954 33 14H37ZM33.5858 57.4142C34.3668 58.1953 35.6332 58.1953 36.4142 57.4142L49.1421 44.6863C49.9232 43.9052 49.9232 42.6389 49.1421 41.8579C48.3611 41.0768 47.0948 41.0768 46.3137 41.8579L35 53.1716L23.6863 41.8579C22.9052 41.0768 21.6389 41.0768 20.8579 41.8579C20.0768 42.6389 20.0768 43.9052 20.8579 44.6863L33.5858 57.4142ZM33 14V56H37V14H33Z" fill="white"/>
                        </svg>
                    </a>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default HeroSection;