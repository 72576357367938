import React from "react";
import "./style.scss";
import { images } from "../../../assets";

const About = () =>{
 return(
    <>
    {/* About Page */}
    
    <section className="pageintro">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>We Build a Bridge between <br/>companies and customers</h2>
                    <p>we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate<br/> and overcome these obstacles with ease.</p>
                </div>
            </div>
        </div>
    </section>

    <div className="section2">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <img src={images.groupimg} alt="" />
                </div>
                <hr />
                <div className="col-sm-5 col-12">
                    <h3>Together We Are Strong</h3>
                </div>
                <div className="col-sm-7 col-12">
                    <p><b>we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate.</b></p>
                    <p>We understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease.</p>
                </div>
            </div>
        </div>
    </div>

    <div className="section3">
        <div className="container">
            <div className="row">
                <div className="col-12 align-center title">
                        <h3>Our Values</h3>
                        <h2><span>5 Things</span> We Value</h2>
                </div>
                <div className="d-flex">
                    <div className="box b1">
                        <h3>Harnessing the latest tech to deliver cutting-edge solutions.</h3>
                    </div>
                    <div className="box b2">
                        <h3>Harnessing the latest tech to deliver cutting-edge solutions.</h3>
                    </div>
                    <div className="box b3">
                        <h3>Harnessing the latest tech to deliver cutting-edge solutions.</h3>
                    </div>
                    <div className="box b4">
                        <h3>Harnessing the latest tech to deliver cutting-edge solutions.</h3>
                    </div>
                    <div className="box b5">
                        <h3>Harnessing the latest tech to deliver cutting-edge solutions.</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="section4">
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h2>What We DO</h2>
                    <div className="d-flex">
                        <img src={images.wwd1} alt="" />
                        <img src={images.wwd2} alt="" />
                        <img src={images.wwd3} alt="" />
                        <img src={images.wwd4} alt="" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="section5">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>“we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease. we understand that running.”</h2>
                </div>
            </div>
        </div>
    </div>

    <div className="startproject">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <h2>Want to Start <br/>a project?</h2>
                    <button className="button"><a href="https://calendly.com/redmotionlab">Book A Call</a></button>
                </div>
            </div>
        </div>
    </div>



    </>
 )   
}

export default About;