import logo from './img/header/logo.png';
import bgelement from './img/home/elementbg.png';
import heroimg from './img/home/imgherosec.png';
import serviceimg from './img/home/services.png';
import webdesigningcategory from './img/home/web-cat.png';
import graphicdesigingcategory from './img/home/graphic-cat-1.png';
import digitalMarketingcategory from './img/home/digital-marketing-cat.png';
import groupimg1 from './img/home/groupimg1.png';
import whitelogo from './img/footer/whitelogo.png';
import groupimg from './img/About/groupimg.png';
import wwd1 from './img//About/wwd1.png';
import wwd2 from './img//About/wwd2.png';
import wwd3 from './img//About/wwd3.png';
import wwd4 from './img//About/wwd4.png';
import webimg from './img/Services/webdevelop.png';
import graphicimg from './img/Services/graphic.png';
import contentimg from './img/Services/content.png';
import digitalimg from './img/Services/digital.png';
import profilephoto from './img/Services/profilephoto.png';
import portfolio1 from './img/portfolio/portfolio.png';
import portfolio2 from './img/portfolio/portfolio2.png';
import portfolio3 from './img/portfolio/portfolio3.png';
import portfolio4 from './img/portfolio/portfolio4.png';
import portfolio5 from './img/portfolio/portfolio5.png';
import portfolio6 from './img/portfolio/portfolio6.png';
import portfolio9 from './img/portfolio/portfolio9.png';
import workimg1 from './img/portfolio/mockups/img1.jpg';
import workimg2 from './img/portfolio/mockups/img2.png';
import workimg3 from './img/portfolio/mockups/img3.jpg';
import workimg4 from './img/portfolio/mockups/img4.png';
import workimg5 from './img/portfolio/mockups/img5.jpg';
import workimg6 from './img/portfolio/mockups/img6.jpg';
import workimg7 from './img/portfolio/mockups/img7.png';
import workimg8 from './img/portfolio/mockups/img8.png';
import workimg9 from './img/portfolio/mockups/img9.png';
import workimg10 from './img/portfolio/mockups/img10.png';
import workimg11 from './img/portfolio/mockups/img11.png';
import mockup1 from './img/portfolio/mockups/mockup1.png';
import mockup2 from './img/portfolio/mockups/mockup2.png';
import mockup3 from './img/portfolio/mockups/mockup3.png';
import post1 from './img/portfolio/mockups/post1.png';
import post2 from './img/portfolio/mockups/post2.png';
import post3 from './img/portfolio/mockups/post3.png';
import post4 from './img/portfolio/mockups/post4.png';
import post5 from './img/portfolio/mockups/post5.png';

export const images={
    logo,
    bgelement,
    heroimg,
    serviceimg,
    webdesigningcategory,
    graphicdesigingcategory,
    digitalMarketingcategory,
    groupimg1,
    whitelogo,
    groupimg,
    wwd1,
    wwd2,
    wwd3,
    wwd4,
    webimg,
    graphicimg,
    contentimg,
    digitalimg,
    profilephoto,
    portfolio1,
    portfolio2,
    portfolio3,
    portfolio4,
    portfolio5,
    portfolio6,
    portfolio9,
    workimg1,
    workimg2,
    workimg3,
    workimg4,
    workimg5,
    workimg6,
    workimg7,
    workimg8,
    workimg9,
    workimg10,
    workimg11,
    mockup1,
    mockup2,
    mockup3,
    groupimg,
    post1,
    post2,
    post3,
    post4,
    post5,
}
