import React from "react";
import { images } from "../../../assets";
import './style.scss';

const Footer =()=>{
    return(
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-sm-2 col-12">
                        <img src={images.whitelogo} alt="" />
                    </div>
                    <div className="col-sm-4 col-12">
                        <div className="headline">
                            About
                        </div>
                        <p>Share your goals, thoughts, and ideas, and we will explore concepts, design, and build great online experiences making customers and users love and share your brand.</p>
                    </div>
                    <div className="col-sm-3 col-12">                        
                        <div className="headline">
                            Services
                        </div>
                        <ul>
                            <li><a href="/">Web Development</a></li>
                            <li><a href="/">Graphic Designing</a></li>
                            <li><a href="/">Logo Designing</a></li>
                            <li><a href="/">Social Media Marketing</a></li>
                            <li><a href="/">Meta Advertisement</a></li>
                            <li><a href="/">Web Designing</a></li>
                        </ul>
                    </div>
                    <div className="col-sm-3 col-12">                                             
                        <div className="headline">
                            Quick Link
                        </div>
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><a href="/about">About</a></li>
                            <li><a href="/services">Services </a></li>
                            <li><a href="/contact">Contact  </a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="copyright">
                    Copyright REDMOTIONLAB All rights reserved-2024.
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;