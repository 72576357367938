import React from "react";
import './style.scss';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import { images } from "../../assets";

const Testimonials=()=>{    
    const option = {
        responsiveClass:true,
        responsive: {
            0: {
            items: 1,
            
            },
            1200: {
            items: 3,
            },
        }, 
    }
    return(
        <div className="testimonials">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 title">
                        
                        <h4>Clients Review</h4>
                        <h2>Testimonials</h2>
                        
                    </div>
                    <div className="col-12">
                        <OwlCarousel className='owl-theme' items={3} {...option} dots={false} loop margin={30} center autoplay autoplaySpeed={400}>
                            <div className="item">
                                <img src={images.profilephoto} className="profile" alt="" />
                                <div className="clientname"><b>Neha K</b></div>
                                <div className="post">Owner, Hireling Pvt. Ltd.</div>

                                <p>Amazing experience! They transformed our outdated website into a modern, user-friendly platform that truly reflects our brand.</p>
                            </div>
                            <div className="item">
                                <img src={images.profilephoto} className="profile" alt="" />
                                <div className="clientname"><b>Rohit S.</b></div>
                                <div className="post">Founder, Playzone Retro </div>

                                <p>Our online store couldn’t be better! From seamless navigation to secure checkout, everything runs smoothly. Thank you guys for the Amazing work.</p>
                            </div>
                            <div className="item">
                                <img src={images.profilephoto} className="profile" alt="" />
                                <div className="clientname"><b>Sarah M.</b></div>
                                <div className="post">Manager, Pero power</div>

                                <p>Their designs are pure magic! The creativity and precision they brought to our branding blew us away. I always get their 100% result from redmotion.</p>
                            </div>
                            <div className="item">
                                <img src={images.profilephoto} className="profile" alt="" />
                                <div className="clientname"><b>Vikram J.</b></div>
                                <div className="post">CEO, FitLife Pvt. Ltd.</div>

                                <p>I am humbled with the quality of work that I recieved not just that they helped us grow our online presence. Thanks to their expertise.  </p>
                            </div>  
                        </OwlCarousel>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Testimonials;