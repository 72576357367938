import { Navigate, useRoutes } from "react-router-dom";
import Layout from "../View/Layout/Layout";
import Home from "../View/Pages/Home/Home";
import About from "../View/Pages/About/About";
import Servicespage from "../View/Pages/Services";
import Contactpage from "../View/Pages/Contact";
import Portfolio from "../View/Pages/Portfolio";

const Routes =()=>{
    const element = useRoutes([
        {
            path:'/',
            element: <Layout/>,
            children:[
                {
                    path:'/',
                    element:<Home/>
                },
                {
                    path:'/about',
                    element:<About/>
                },
                {
                    path:'/services',
                    element:<Servicespage/>
                },
                {
                    path:'/contact',
                    element:<Contactpage/>
                },
                {
                    path:'/portfolio',
                    element:<Portfolio/>
                }
            ]
        },
    ])
    return element;
}

export default Routes;