import React from "react";
import { images } from "../../assets";
import './style.scss';

const BookingSection=()=>{
    return(
            <section className="booking-section">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-12">
                            <img src={images.groupimg1} alt="" />
                        </div>
                        <div className="col-sm-8 col-12">
                            <div className="content">
                            <h2>Are You ready to transform not only your business, but your life?</h2> 
                            <button className="button1"><a href="https://calendly.com/redmotionlab" target="_blank">Book A Call</a></button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    )
}
export default BookingSection;