import React from "react";
import { images } from "../../../assets";
import './style.scss';

const ServicesSection = () =>{
    return(
        <section className="Service-section">
            <div className="container">
                <div className="row">
                    <div className="col-12 title">
                        <h6>Our Services</h6>
                        <h2>What We <span>Offer</span></h2>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="redbox">
                            <h3>Services We Can <br/> Help You With...</h3>
                            <svg width="76" height="30" viewBox="0 0 76 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M75.4142 16.4142C76.1953 15.6332 76.1953 14.3668 75.4142 13.5858L62.6863 0.857864C61.9052 0.0768156 60.6389 0.0768156 59.8579 0.857864C59.0768 1.63891 59.0768 2.90524 59.8579 3.68629L71.1716 15L59.8579 26.3137C59.0768 27.0948 59.0768 28.3611 59.8579 29.1421C60.6389 29.9232 61.9052 29.9232 62.6863 29.1421L75.4142 16.4142ZM0 17H74V13H0V17Z" fill="white"/>
                            </svg>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12">
                        <div className="servicebox">
                            <div className="svgcover">
                                <a href="/services">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.891609 19.9893C0.333427 20.6015 0.377175 21.5502 0.989322 22.1084C1.60147 22.6666 2.55021 22.6228 3.10839 22.0107L0.891609 19.9893ZM21.1536 1.56885C21.1154 0.741299 20.4136 0.101373 19.5861 0.139532L6.10039 0.761372C5.27284 0.799532 4.63291 1.50133 4.67107 2.32887C4.70923 3.15642 5.41103 3.79635 6.23857 3.75819L18.2258 3.20544L18.7786 15.1927C18.8167 16.0203 19.5185 16.6602 20.3461 16.622C21.1736 16.5839 21.8136 15.8821 21.7754 15.0545L21.1536 1.56885ZM3.10839 22.0107L20.7635 2.64862L18.5468 0.627262L0.891609 19.9893L3.10839 22.0107Z" fill="white"/>
                                </svg>
                                </a>
                            </div>
                            <h4>Website <br/>Development </h4>
                            <p>We help you in building the right kind of website your business needs: whether it’s dynamic and interactive, or sophisticated and static.</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12">
                        <div className="servicebox">
                            <div className="svgcover">
                                <a href="/services">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.891609 19.9893C0.333427 20.6015 0.377175 21.5502 0.989322 22.1084C1.60147 22.6666 2.55021 22.6228 3.10839 22.0107L0.891609 19.9893ZM21.1536 1.56885C21.1154 0.741299 20.4136 0.101373 19.5861 0.139532L6.10039 0.761372C5.27284 0.799532 4.63291 1.50133 4.67107 2.32887C4.70923 3.15642 5.41103 3.79635 6.23857 3.75819L18.2258 3.20544L18.7786 15.1927C18.8167 16.0203 19.5185 16.6602 20.3461 16.622C21.1736 16.5839 21.8136 15.8821 21.7754 15.0545L21.1536 1.56885ZM3.10839 22.0107L20.7635 2.64862L18.5468 0.627262L0.891609 19.9893L3.10839 22.0107Z" fill="white"/>
                                </svg>
                                </a>
                            </div>
                            <h4>Graphic <br/>Designing </h4>
                            <p>A compelling brand communicates the character, voice, and persona. For us, design is way more than just a skill. It represents your brand to your audience.  </p>
                        </div>                    
                    </div>
                </div>
                <div className="row">
                    {/* Second Row */}
                    <div className="col-sm-3 col-12">
                        <img src={images.serviceimg} alt="" style={{height:'260px',objectFit:'cover',width:'100%',paddingBottom:'20px'}}/>
                    </div>
                    <div className="col-sm-3 col-12">
                        <div className="servicebox">
                            <div className="svgcover">
                                <a href="/services">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.891609 19.9893C0.333427 20.6015 0.377175 21.5502 0.989322 22.1084C1.60147 22.6666 2.55021 22.6228 3.10839 22.0107L0.891609 19.9893ZM21.1536 1.56885C21.1154 0.741299 20.4136 0.101373 19.5861 0.139532L6.10039 0.761372C5.27284 0.799532 4.63291 1.50133 4.67107 2.32887C4.70923 3.15642 5.41103 3.79635 6.23857 3.75819L18.2258 3.20544L18.7786 15.1927C18.8167 16.0203 19.5185 16.6602 20.3461 16.622C21.1736 16.5839 21.8136 15.8821 21.7754 15.0545L21.1536 1.56885ZM3.10839 22.0107L20.7635 2.64862L18.5468 0.627262L0.891609 19.9893L3.10839 22.0107Z" fill="white"/>
                                </svg>
                                </a>
                            </div>
                            <h4>Digital <br/>Marketing</h4>
                            <p>it‘s not enough to tell your brand’s story. You need to engage with the right audience and meet them at the right time. With a focus on strategy using our experience.</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12">
                        <div className="servicebox">
                            <div className="svgcover">
                                <a href="/services">
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.891609 19.9893C0.333427 20.6015 0.377175 21.5502 0.989322 22.1084C1.60147 22.6666 2.55021 22.6228 3.10839 22.0107L0.891609 19.9893ZM21.1536 1.56885C21.1154 0.741299 20.4136 0.101373 19.5861 0.139532L6.10039 0.761372C5.27284 0.799532 4.63291 1.50133 4.67107 2.32887C4.70923 3.15642 5.41103 3.79635 6.23857 3.75819L18.2258 3.20544L18.7786 15.1927C18.8167 16.0203 19.5185 16.6602 20.3461 16.622C21.1736 16.5839 21.8136 15.8821 21.7754 15.0545L21.1536 1.56885ZM3.10839 22.0107L20.7635 2.64862L18.5468 0.627262L0.891609 19.9893L3.10839 22.0107Z" fill="white"/>
                                </svg>
                                </a>
                            </div>
                            <h4>Business <br/>Consultancy</h4>
                            <p>The path toward creating a superior brand starts with robust strategies and thoughtful insights. We help companies to build a solid foundation by combining methods.</p>
                        </div>
                    </div>
                    <div className="col-sm-3 col-12">
                        <div className="servicebox">
                            <div className="svgcover">
                                <a href="/services">                                
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.891609 19.9893C0.333427 20.6015 0.377175 21.5502 0.989322 22.1084C1.60147 22.6666 2.55021 22.6228 3.10839 22.0107L0.891609 19.9893ZM21.1536 1.56885C21.1154 0.741299 20.4136 0.101373 19.5861 0.139532L6.10039 0.761372C5.27284 0.799532 4.63291 1.50133 4.67107 2.32887C4.70923 3.15642 5.41103 3.79635 6.23857 3.75819L18.2258 3.20544L18.7786 15.1927C18.8167 16.0203 19.5185 16.6602 20.3461 16.622C21.1736 16.5839 21.8136 15.8821 21.7754 15.0545L21.1536 1.56885ZM3.10839 22.0107L20.7635 2.64862L18.5468 0.627262L0.891609 19.9893L3.10839 22.0107Z" fill="white"/>
                                </svg>
                                </a>
                            </div>
                            <h4>Content <br/> Writing </h4>
                            <p>With the right content you can convey right messaage and what's in your mind to your the right audience. Your content represent your brand to your audience.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServicesSection;