import React from "react";
import './style.scss';
import { images } from "../../../assets";

const PortfolioSection =()=> {
    return(
        <section className="portfolio-section">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="title">
                            <h2>Still Not Convinced?</h2>
                            <h4>Check out on <span>our Work</span> here</h4>
                        </div>
                    </div>
                    <div className="col-sm-4 col-12">
                        <div className="portfolio-category">
                            <img src={images.webdesigningcategory} alt="" />
                            <div className="button2">
                                View Web Designs
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-4 col-12">
                        <img src={images.graphicdesigingcategory} alt="" />
                        <div className="button2">
                            View Graphic Designs
                        </div>
                    </div>
                    <div className="col-sm-4 col-12">
                        <img src={images.digitalMarketingcategory} alt="" />
                        <div className="button2">
                            View Digital Marketing
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PortfolioSection;