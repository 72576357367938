import React from "react";
import './style.scss';

const WefixbusinessSection=()=>{
    return(
        <section className="section02">
            <div className="container" id="business">
                <div className="row">
                    <div className="col-sm-6 col-12 d-flex align-items-center">
                        <div>
                            <h3>We <span>Fix</span> Business</h3>
                            <p>we understand that running a business comes with its own set of challenges. That's why we're here to help you navigate and overcome these obstacles with ease.</p> 
                        </div>
                    </div>
                    <div className="col-sm-6 col-12">
                        <div className="booking-box">
                            <h5>Wanting to grow your business?</h5>
                            <h6>Wanna know how?</h6>
                            <button className="button1"><a href="https://calendly.com/redmotionlab" target="_blank">Book A Call</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default WefixbusinessSection;