import React from "react";
import { images } from "../../../assets";
import './style.scss';

const Header =()=>{
    return(
        <>
            <header>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-12">
                        <a href="/"><img src={images.logo} alt="red motion lab logo" /></a>   
                        </div>
                        <div className="col-sm-8 col-12">
                            <div className="menu">
                                <ul calssName="menu">
                                    <li><a href="/">Home</a></li>
                                    <li><a href="/about">About</a></li>
                                    <li><a href="/services">Services</a></li>
                                    <li><a href="/portfolio">Portfolio</a></li>
                                    <li><a href="/contact">Contact</a></li>
                                </ul>
                                <div className="hamburgermenu">
                                                                            
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </header>   
        </>
    )
}

export default Header;