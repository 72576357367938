import React from "react";
import HeroSection from "../../../components/Home/Hero-section/hero-section";
import WefixbusinessSection from "../../../components/Home/Wefixbusiness";
import ServicesSection from "../../../components/Home/Servicessection";
import PortfolioSection from "../../../components/Home/Portfolio";
import BookingSection from "../../../components/bookacallsection/index";
import ContactSection from "../../../components/contactsection";

const Home = ()=> {
    return(
        <>        
            <HeroSection/>
            <WefixbusinessSection/>
            <ServicesSection/>
            <PortfolioSection/>
            <BookingSection/>
            <ContactSection/>
        </>
    )
}

export default Home;