import React from "react";
import './style.scss';
import { images } from "../../../assets";  
import Testimonials from "../../../components/Testimonials";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Portfolio = () =>{    
    const option = {
        responsiveClass:true,
        responsive: {
            0: {
            items: 1,
            
            },
            1200: {
            items: 3,
            },
        }, 
    } 
    const option2 = {
        responsiveClass:true,
        responsive: {
            0: {
            items: 1,
            
            },
            1200: {
            items: 4,
            },
        }, 
    }
    return(
        <>
        <div className="section1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-9 col-12">
                        <h2>Our <span>Portfolio</span></h2>
                        <p>Showcasing Creativity, Strategy, and Impactful Solutions Through Design and Innovation.</p>
                    </div>
                </div>
            </div>
        </div>

        <div className="latest-project">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Latest Project</h2>
                        <hr />
                    </div>
                    <OwlCarousel className='owl-theme' items={3} {...option} dots={false} loop margin={20} center autoplay autoplaySpeed={400}>
                        <div className="lp1">
                            <h4>Xponentfunds</h4>
                            <p>Trading and stocks </p>                        
                            <img src={images.portfolio2} alt=""/>
                            <a href="https://xponentfunds.in/" target="_blank">Have a look 
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="16.4936" cy="15.9618" rx="16.3222" ry="15.3846" fill="#000"/>
                                    <path d="M9.20646 21.4644C8.82245 21.8613 8.8507 22.4749 9.26954 22.8349C9.68839 23.1949 10.3392 23.165 10.7232 22.768L9.20646 21.4644ZM22.5196 10.1538C22.495 9.61856 22.0148 9.20586 21.4471 9.23199L12.1959 9.65784C11.6282 9.68397 11.1879 10.139 11.2126 10.6743C11.2372 11.2095 11.7174 11.6222 12.2851 11.5961L20.5084 11.2175L20.8653 18.9704C20.8899 19.5056 21.3701 19.9183 21.9378 19.8922C22.5055 19.866 22.9457 19.411 22.9211 18.8758L22.5196 10.1538ZM10.7232 22.768L22.2501 10.8529L20.7333 9.5493L9.20646 21.4644L10.7232 22.768Z" fill="white"/>
                                </svg>
                            </a>
                        </div>                         
                        <div className="lp1">
                            <h4>Minimoda India</h4>
                            <p>Online E-commerce</p>
                            <img src={images.portfolio1} alt="" />
                            <a href="https://www.minimodaindia.com/" target="_blank">Have a look 
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="16.4936" cy="15.9618" rx="16.3222" ry="15.3846" fill="#000"/>
                                    <path d="M9.20646 21.4644C8.82245 21.8613 8.8507 22.4749 9.26954 22.8349C9.68839 23.1949 10.3392 23.165 10.7232 22.768L9.20646 21.4644ZM22.5196 10.1538C22.495 9.61856 22.0148 9.20586 21.4471 9.23199L12.1959 9.65784C11.6282 9.68397 11.1879 10.139 11.2126 10.6743C11.2372 11.2095 11.7174 11.6222 12.2851 11.5961L20.5084 11.2175L20.8653 18.9704C20.8899 19.5056 21.3701 19.9183 21.9378 19.8922C22.5055 19.866 22.9457 19.411 22.9211 18.8758L22.5196 10.1538ZM10.7232 22.768L22.2501 10.8529L20.7333 9.5493L9.20646 21.4644L10.7232 22.768Z" fill="white"/>
                                </svg>
                            </a>
                        </div>
                        <div className="lp1">
                            <h4>Power Squared</h4>
                            <p>Energy management Company</p>
                            <img src={images.portfolio5} alt="" />
                            <a href="https://powersquared.tech/" target="_blank">Have a look 
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="16.4936" cy="15.9618" rx="16.3222" ry="15.3846" fill="#000"/>
                                    <path d="M9.20646 21.4644C8.82245 21.8613 8.8507 22.4749 9.26954 22.8349C9.68839 23.1949 10.3392 23.165 10.7232 22.768L9.20646 21.4644ZM22.5196 10.1538C22.495 9.61856 22.0148 9.20586 21.4471 9.23199L12.1959 9.65784C11.6282 9.68397 11.1879 10.139 11.2126 10.6743C11.2372 11.2095 11.7174 11.6222 12.2851 11.5961L20.5084 11.2175L20.8653 18.9704C20.8899 19.5056 21.3701 19.9183 21.9378 19.8922C22.5055 19.866 22.9457 19.411 22.9211 18.8758L22.5196 10.1538ZM10.7232 22.768L22.2501 10.8529L20.7333 9.5493L9.20646 21.4644L10.7232 22.768Z" fill="white"/>
                                </svg>
                            </a>
                        </div>
                        <div className="lp1">
                            <h4>Playzone Retro</h4>
                            <p>Online E-commerce </p>
                            <img src={images.portfolio3} alt="" />
                            <a href="https://playzoneretro.shop/" target="_blank">Have a look 
                                <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="16.4936" cy="15.9618" rx="16.3222" ry="15.3846" fill="#000"/>
                                    <path d="M9.20646 21.4644C8.82245 21.8613 8.8507 22.4749 9.26954 22.8349C9.68839 23.1949 10.3392 23.165 10.7232 22.768L9.20646 21.4644ZM22.5196 10.1538C22.495 9.61856 22.0148 9.20586 21.4471 9.23199L12.1959 9.65784C11.6282 9.68397 11.1879 10.139 11.2126 10.6743C11.2372 11.2095 11.7174 11.6222 12.2851 11.5961L20.5084 11.2175L20.8653 18.9704C20.8899 19.5056 21.3701 19.9183 21.9378 19.8922C22.5055 19.866 22.9457 19.411 22.9211 18.8758L22.5196 10.1538ZM10.7232 22.768L22.2501 10.8529L20.7333 9.5493L9.20646 21.4644L10.7232 22.768Z" fill="white"/>
                                </svg>
                            </a>
                        </div>
                    </OwlCarousel>                    
                </div>
            </div>
        </div>

        <div className="ourwork">
            <div className="container">
                <div className="row">
                    <div className="col-12 title">
                        <span>Our Portfolios</span>
                        <h2>Our <span>Work</span> </h2>
                    </div>
                </div>
            </div>
            <div className="container"> 
                <div className="row justify-content-center pb-3">
                    <div className="col-sm-6 col-12 order-sm-1 order-2 work-desc d-flex align-items-center">
                        <div>
                            <h3>Business <span>Portfolios</span></h3>
                            <h5>Hireling</h5>
                            <p>Developing the business portfolio for Hireling was a rewarding experience, allowing us to strategically showcase their offerings, values, and vision. The process involved aligning design elements with their business goals to create a polished and impactful presentation.
                            </p>
                            <button className="button1"><a href="mailto:info@redmotionlab.com">Get A Quote</a></button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-sm-2 order-1">
                        <img src={images.mockup1} alt="" />
                    </div>
                </div>
                <div className="row justify-content-center  pb-3">
                    <div className="col-sm-6 col-12 order-sm-1 order-1">
                        <img src={images.mockup2} alt="" />
                    </div>
                    <div className="col-sm-6 col-12 order-sm-2 order-2 work-desc d-flex align-items-center">
                        <div>
                            <h3>Logo <span>Design</span></h3>
                            <h5>Urban Full House Cleaning</h5>
                            <p>Designing the logo for Urban Full House Cleaners was all about capturing the essence of cleanliness, professionalism, and attention to detail. The final design reflects their core values while maintaining a modern, sleek look that resonates with their target audience.</p>
                            <button className="button1"><a href="mailto:info@redmotionlab.com">Get A Quote</a></button>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center pb-3">
                    <div className="col-sm-6 col-12 order-sm-1 order-2 work-desc d-flex align-items-center">
                        <div>
                            <h3>Powerpoint <span>Presentation</span></h3>
                            <h5>Hireling</h5>
                            <p>For Fount Head, We created a visually compelling PowerPoint presentation that combines storytelling with design finesse. The goal was to ensure each slide communicated key messages effectively, enhancing engagement and leaving a lasting impression on their audience.</p>
                            <button className="button1"><a href="mailto:info@redmotionlab.com">Get A Quote</a></button>
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-sm-2 order-1">
                        <img src={images.mockup3} alt="" />
                    </div>
                </div>
            </div>
        </div>
        
        
        <div className="container websitework">
            <div className="row">
                <div className="col-sm-9 col-12 d-flex align-items-center">
                    <div style={{padding:'0.4px', background:'#ffffff40',width:'100%'}}></div>
                </div>
                <div className="col-sm-3 col-12">
                <h2>Website <br/> <span>Design</span></h2>
                </div>
            </div>
            <OwlCarousel className='owl-theme' items={3} {...option} dots loop margin={20} center autoplay autoplaySpeed={400}>
                <div className="lp1">                    
                    <img src={images.portfolio6} alt=""/>
                </div>                 
                <div className="lp1">                    
                    <img src={images.portfolio9} alt=""/>
                </div>                    
                <div className="lp1">                    
                    <img src={images.portfolio2} alt=""/>
                </div>                              
                <div className="lp1">
                    <img src={images.portfolio1} alt="" />
                </div>
                <div className="lp1">
                    <img src={images.portfolio5} alt="" />
                </div>
                <div className="lp1">
                    <img src={images.portfolio3} alt="" />
                </div>
            </OwlCarousel>    
        </div>

        
        <div className="container instawork">
            <div className="row">
                <div className="col-sm-4 col-12">
                <h2>Social Media <br/> <span> Post Design</span></h2>
                </div>
                <div className="col-sm-8 col-12 d-flex align-items-center">
                    <div style={{padding:'0.4px', background:'#ffffff40',width:'100%'}}></div>
                </div>
            </div>
        </div>
        <div className="container-fluid instawork">
            <OwlCarousel className='owl-theme' items={4} {...option2} dots loop margin={30} center autoplay autoplaySpeed={400}>
                <div className="lp1">                    
                    <img src={images.post1} alt=""/>
                </div>                 
                <div className="lp1">                    
                    <img src={images.post2} alt=""/>
                </div>                    
                <div className="lp1">                    
                    <img src={images.post3} alt=""/>
                </div>                              
                <div className="lp1">
                    <img src={images.post4} alt="" />
                </div>
                <div className="lp1">
                    <img src={images.post5} alt="" />
                </div>
            </OwlCarousel>    
        </div>

        <Testimonials/>

        <div className="startproject">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Want to Start <br/>a project?</h2>
                        <button className="button"><a href="https://calendly.com/redmotionlab" target="_blank">Book A Call</a></button>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default Portfolio;