import React from "react";
import './style.scss';
import { images } from "../../../assets";   
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Testimonails from "../../../components/Testimonials";


const Servicespage = () =>{

    return(
        <>

        <div className="section1">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-sm-8 col-12">
                        <h2>Services For <span>Smooth</span> <br/>
                        Business Flow</h2>
                        <p>Delivering innovative design, seamless development, and strategic marketing solutions tailored to drive your business forward.</p>
                        <div className="d-flex">
                            <button className="button1 buttonwhite"><a href="" style={{color:'#000'}}>Chat On whatsapp</a></button>
                            <button className="button1 buttonred"><a href="/portfolio">View Portfolio</a></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div className="servicessection2">
            <div className="container">
                <div className="row">
                    <div className="col-sm-2 col-12" >
                        <h2>Our Services.</h2>
                    </div>
                    <div className="col-sm-1 col-12"></div>
                    <div className="col-sm-9 col-12">
                        <p>We offer a comprehensive range of services, including web design, e-commerce solutions, graphic design, and digital marketing. From creating stunning visuals to driving online growth, we tailor every project to meet your business goals. Let us turn your ideas into impactful results</p>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="servicebox">
                        <div className="row">
                            <div className="col-sm-6 col-12 p1 order-sm-1 order-2"> 
                                <h3>Website Designing <br/>& Development</h3>
                                <a href="#"> 
                                    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="15.6196" cy="15.3846" rx="15.5571" ry="15.3846" fill="black"/>
                                    <path d="M8.65383 20.8694C8.27945 21.2754 8.30845 21.9044 8.71861 22.2742C9.12876 22.6441 9.76476 22.6148 10.1391 22.2087L8.65383 20.8694ZM21.3875 9.57764C21.3622 9.02901 20.892 8.605 20.3372 8.63057L11.297 9.0474C10.7422 9.07298 10.313 9.53847 10.3383 10.0871C10.3636 10.6357 10.8338 11.0597 11.3886 11.0342L19.4243 10.6636L19.7908 18.6107C19.8161 19.1593 20.2863 19.5833 20.841 19.5577C21.3958 19.5322 21.825 19.0667 21.7997 18.518L21.3875 9.57764ZM10.1391 22.2087L21.1257 10.2936L19.6404 8.95428L8.65383 20.8694L10.1391 22.2087Z" fill="white"/>
                                    </svg>
                                    Learn More
                                </a>                                
                            </div>
                            <div className="col-sm-6 col-12 order-sm-2 order-1 ">
                                <img src={images.webimg} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="servicebox">
                        <div className="row">
                            <div className="col-sm-6 col-12 order-sm-1 order-2 p1"> 
                                <h3>Graphic <br/>Desiging</h3>
                                <a href="#"> 
                                    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="15.6196" cy="15.3846" rx="15.5571" ry="15.3846" fill="black"/>
                                    <path d="M8.65383 20.8694C8.27945 21.2754 8.30845 21.9044 8.71861 22.2742C9.12876 22.6441 9.76476 22.6148 10.1391 22.2087L8.65383 20.8694ZM21.3875 9.57764C21.3622 9.02901 20.892 8.605 20.3372 8.63057L11.297 9.0474C10.7422 9.07298 10.313 9.53847 10.3383 10.0871C10.3636 10.6357 10.8338 11.0597 11.3886 11.0342L19.4243 10.6636L19.7908 18.6107C19.8161 19.1593 20.2863 19.5833 20.841 19.5577C21.3958 19.5322 21.825 19.0667 21.7997 18.518L21.3875 9.57764ZM10.1391 22.2087L21.1257 10.2936L19.6404 8.95428L8.65383 20.8694L10.1391 22.2087Z" fill="white"/>
                                    </svg>
                                    Learn More
                                </a>                                
                            </div>
                            <div className="col-sm-6 col-12  order-sm-2 order-1">
                                <img src={images.graphicimg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="d-flex">
                    <div className="servicebox">
                        <div className="row">
                            <div className="col-sm-6 col-12 order-sm-1 order-2 p1"> 
                                <h3>Digital<br/> Marketing</h3>
                                <a href="#"> 
                                    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="15.6196" cy="15.3846" rx="15.5571" ry="15.3846" fill="black"/>
                                    <path d="M8.65383 20.8694C8.27945 21.2754 8.30845 21.9044 8.71861 22.2742C9.12876 22.6441 9.76476 22.6148 10.1391 22.2087L8.65383 20.8694ZM21.3875 9.57764C21.3622 9.02901 20.892 8.605 20.3372 8.63057L11.297 9.0474C10.7422 9.07298 10.313 9.53847 10.3383 10.0871C10.3636 10.6357 10.8338 11.0597 11.3886 11.0342L19.4243 10.6636L19.7908 18.6107C19.8161 19.1593 20.2863 19.5833 20.841 19.5577C21.3958 19.5322 21.825 19.0667 21.7997 18.518L21.3875 9.57764ZM10.1391 22.2087L21.1257 10.2936L19.6404 8.95428L8.65383 20.8694L10.1391 22.2087Z" fill="white"/>
                                    </svg>
                                    Learn More
                                </a>                                
                            </div>
                            <div className="col-sm-6 col-12  order-sm-2 order-1">
                                <img src={images.digitalimg} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className="servicebox">
                        <div className="row">
                            <div className="col-sm-6 col-12 order-sm-1 order-2 p1"> 
                                <h3>Content<br/> Writing</h3>
                                <a href="#"> 
                                    <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <ellipse cx="15.6196" cy="15.3846" rx="15.5571" ry="15.3846" fill="black"/>
                                    <path d="M8.65383 20.8694C8.27945 21.2754 8.30845 21.9044 8.71861 22.2742C9.12876 22.6441 9.76476 22.6148 10.1391 22.2087L8.65383 20.8694ZM21.3875 9.57764C21.3622 9.02901 20.892 8.605 20.3372 8.63057L11.297 9.0474C10.7422 9.07298 10.313 9.53847 10.3383 10.0871C10.3636 10.6357 10.8338 11.0597 11.3886 11.0342L19.4243 10.6636L19.7908 18.6107C19.8161 19.1593 20.2863 19.5833 20.841 19.5577C21.3958 19.5322 21.825 19.0667 21.7997 18.518L21.3875 9.57764ZM10.1391 22.2087L21.1257 10.2936L19.6404 8.95428L8.65383 20.8694L10.1391 22.2087Z" fill="white"/>
                                    </svg>
                                    Learn More
                                </a>                                
                            </div>
                            <div className="col-sm-6 col-12  order-sm-2 order-1">
                                <img src={images.contentimg} alt="" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            
        </div>
        <div className="d-flex justify-content-center">
            <div className="container servicecto">            
                <div className="row">
                    <div className="col-12 ">
                        <h3>Lets make this happen.</h3>
                        <p>Ready to elevate your business? Let’s bring your vision to life—get in touch with us today!</p>
                        <button className="button1">Contact Us</button>
                    </div>
                </div>
            </div>            
        </div>

        <Testimonails/>

        <div className="faq">
            <div className="container">
                <div className="row">
                    <div className="col-sm-5 col-12">
                        <h2>Frequently Asked Question</h2>
                        <p>Find quick answers to common questions about our services, processes, and how we can help your business grow.</p>
                    </div>
                    <div className="col-sm-7 col-12">
                    <Accordion allowZeroExpanded>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What Digital Marketing  Servicse Do You Offer?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                We provide SEO, PPC (pay-per-click) campaigns, social media marketing, email marketing, and content marketing services.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    What is the cost of a website?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                The cost depends on the features, number of pages, and custom requirements. We offer flexible packages to suit various budgets.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                    Do you offer website maintenance and support?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p>
                                Yes, we provide ongoing maintenance plans to ensure your website runs smoothly and stays updated with the latest technologies.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem> 
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Can you run ad campaigns on Google and Facebook?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p> Yes, we create and manage campaigns on multiple platforms, including Google Ads, Facebook, Instagram, and LinkedIn.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Do you manage social media accounts?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p> Yes, we offer full social media management, including content creation, posting schedules, and performance analysis.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                        <AccordionItem>
                            <AccordionItemHeading>
                                <AccordionItemButton>
                                Can you design custom graphics for websites and social media?
                                </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <p> Yes, we create custom graphics to match your brand identity for use on websites, social media platforms, and marketing campaigns.
                                </p>
                            </AccordionItemPanel>
                        </AccordionItem>
                    </Accordion>

                    </div>
                </div>
            </div>
        </div>

        
        <div className="startproject">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <h2>Want to Start <br/>a project?</h2>
                        <button className="button"><a href="https://calendly.com/redmotionlab" target="_blank">Book A Call</a></button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Servicespage;